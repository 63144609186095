import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ErrorPage = ({ error, resetError, onNavigateHome }) => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);
  const [isActive, setIsActive] = useState(true);

  const handleNavigateHome = useCallback(() => {
    setIsActive(false);
    if (onNavigateHome) {
      onNavigateHome();
    } else {
      resetError();
      navigate('/');
    }
  }, [navigate, onNavigateHome, resetError]);

  useEffect(() => {
    let timer;
    
    if (isActive && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            handleNavigateHome();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else if (countdown === 0) {
      handleNavigateHome();
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [countdown, isActive, handleNavigateHome]);

  const handleTryAgain = () => {
    setIsActive(false);
    window.location.reload();
  };

  const handleReturnHome = () => {
    handleNavigateHome();
  };

  return (
    <ErrorContainer>
      <ErrorContent>
        <ErrorIcon>⚠️</ErrorIcon>
        <ErrorTitle>Oops! Something went wrong</ErrorTitle>
        <ErrorMessage>{error || 'An unexpected error occurred'}</ErrorMessage>
        {isActive && countdown > 0 && (
          <ErrorSubtext>
            You will be redirected to the homepage in {countdown} seconds
          </ErrorSubtext>
        )}
        <ButtonContainer>
          <RetryButton onClick={handleTryAgain}>
            Try Again
          </RetryButton>
          <HomeButton onClick={handleReturnHome}>
            Return to Homepage
          </HomeButton>
        </ButtonContainer>
        <HelpText>
          If this error persists, there may be an internal problem. Please try again later.
        </HelpText>
      </ErrorContent>
    </ErrorContainer>
  );
};

const ErrorContainer = styled.div`
  background: linear-gradient(135deg, #FFF0F5 0%, #FFB6C1 25%, #FFC0CB 50%, #FFB6C1 75%, #FFF0F5 100%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const ErrorContent = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ErrorIcon = styled.div`
  font-size: 48px;
  margin-bottom: 20px;
`;

const ErrorTitle = styled.h1`
  color: #4A7299;
  font-size: 24px;
  margin-bottom: 15px;
  font-family: 'Outfit', sans-serif;
`;

const ErrorMessage = styled.p`
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
`;

const ErrorSubtext = styled.p`
  color: #789DBC;
  font-size: 14px;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
`;

const RetryButton = styled(Button)`
  background-color: #789DBC;
  color: white;
  
  &:hover {
    background-color: #4A7299;
  }
`;

const HomeButton = styled(Button)`
  background-color: white;
  color: #789DBC;
  border: 2px solid #789DBC;
  
  &:hover {
    background-color: #789DBC;
    color: white;
  }
`;

const HelpText = styled.p`
  color: #999;
  font-size: 14px;
  font-style: italic;
`;

export default ErrorPage; 