import React, { useState, useEffect } from 'react';
import './styles/TripMakingPage.css';
import { useFormData } from './context/FormContext';

const TripMakingPage = ({ onNext, onBack }) => {
  const { formData, updateFormData } = useFormData();
  const [startDate, setStartDate] = useState(formData.tripDetails.startDate);
  const [endDate, setEndDate] = useState(formData.tripDetails.endDate);
  const [travelDistance, setTravelDistance] = useState(formData.tripDetails.travelDistance);
  const [budget, setBudget] = useState(formData.tripDetails.budget);
  const [travelGroup, setTravelGroup] = useState(formData.tripDetails.travelGroup);

  // Add this new line to get the current date
  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  useEffect(() => {
    console.log('State variables:', { startDate, endDate, travelDistance, budget, travelGroup });
  }, [startDate, endDate, travelDistance, budget, travelGroup]);

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    
    // If end date is earlier than new start date, update end date
    if (endDate && new Date(endDate) < new Date(newStartDate)) {
      setEndDate(newStartDate);
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (!startDate || new Date(newEndDate) >= new Date(startDate)) {
      setEndDate(newEndDate);
    }
  };

  const handleNext = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const duration = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) + 1;

    const tripData = {
      startDate,
      endDate,
      travelDistance,
      budget: parseFloat(budget),
      travelGroup,
      duration
    };
    console.log('Trip Details Submitted:', tripData);
    updateFormData('tripDetails', tripData);
    onNext(tripData);
  };

  return (
    <div className="page-background">
      <div className="main-container">
        <h2 className="heading">Plan Your Trip</h2>
        <div className="input-container">
          <div className="trip-making-input-wrapper">
            <label htmlFor="startDate" className="label">Start Date:</label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={handleStartDateChange}
              min={today} // Add this line to set the minimum date
              className="input"
            />
          </div>
          <div className="trip-making-input-wrapper">
            <label htmlFor="endDate" className="label">End Date:</label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={handleEndDateChange}
              min={startDate}
              className={`input ${!startDate ? 'disabled' : ''}`}
              disabled={!startDate}
            />
          </div>
          <div className="trip-making-input-wrapper">
            <label htmlFor="travelDistance" className="label">How far do you want to go?</label>
            <select
              id="travelDistance"
              value={travelDistance}
              onChange={(e) => setTravelDistance(e.target.value)}
              className="input select"
            >
              <option value="">Select an option</option>
              <option value="local">Local</option>
              <option value="domestic">Domestic</option>
              <option value="international">International</option>
            </select>
          </div>
          <div className="trip-making-input-wrapper">
            <label htmlFor="budget" className="label">How much do you want to spend? ($)</label>
            <input
              type="number"
              id="budget"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
              min="0"
              step="100"
              className="input"
            />
          </div>
          <div className="trip-making-input-wrapper">
            <label htmlFor="travelGroup" className="label">Who are you traveling with?</label>
            <select
              id="travelGroup"
              value={travelGroup}
              onChange={(e) => setTravelGroup(e.target.value)}
              className="input select"
            >
              <option value="">Select an option</option>
              <option value="solo">Solo</option>
              <option value="friends">Friends</option>
              <option value="family">Family</option>
              <option value="significant_other">Significant Other</option>
            </select>
          </div>
        </div>
        <div className="button-container">
          <button className="back-button" onClick={onBack}>Back</button>
          <button
            className="next-button"
            onClick={handleNext}
            disabled={!startDate || !endDate || !travelDistance || !budget || !travelGroup}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default TripMakingPage;
