// src/components/TravelQuiz.js
import React, { useState, useEffect } from 'react';
import './styles/TravelQuiz.css';

const questions = [
  "I prefer destinations off the beaten path rather than popular tourist spots.",
  "Trying local cuisine is a crucial part of my travel experience.",
  "I prioritize relaxation and stress relief in my travels.",
  "The nightlife and entertainment options are important factors in my destination choice.",
  "I'm interested in historical sites and museums when I travel.",
  "I prefer urban environments over rural or natural settings.",
  "I'm interested in attending local events or festivals during my travels.",
  "I enjoy meeting new people and making friends while traveling.",
  "Natural scenery and outdoor experiences are crucial for my ideal vacation.",
  "Shopping opportunities are an important part of my vacation experience."
];

const TravelQuiz = ({ onSubmit, onBack }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  const handleAnswer = (value) => {
    const button = document.querySelector(`button[data-value="${value}"]`);
    const container = button.parentElement;
    
    container.classList.add('selecting');
    button.classList.add('pop-animation');
    
    setTimeout(() => {
      button.classList.remove('pop-animation');
      container.classList.remove('selecting');
    }, 300);

    const newAnswers = { ...answers, [currentQuestion]: value };
    setAnswers(newAnswers);
    console.log('Quiz Answer Updated:', {
      question: currentQuestion + 1,
      answer: value,
      allAnswers: newAnswers
    });

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      console.log('Quiz Completed:', Object.values(newAnswers));
      onSubmit(Object.values(newAnswers));
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    } else {
      onBack();
    }
  };

  return (
    <div className="quiz-page-background">
      <div className="quiz-main-container">
        <h2 className="quiz-heading">Travel Preference Quiz</h2>
        <div className="quiz-question-wrapper">
          <p className="quiz-question-text">{questions[currentQuestion]}</p>
          <div className="quiz-scale-container">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
              <button 
                key={value} 
                data-value={value} 
                onClick={() => handleAnswer(value)}
                className={`quiz-scale-button ${answers[currentQuestion] === value ? 'selected' : ''}`}
              >
                {value}
              </button>
            ))}
          </div>
          <div className="quiz-scale-legend">
            <span>Completely Disagree</span>
            <span>Completely Agree</span>
          </div>
          <p className="quiz-progress-text">Question {currentQuestion + 1} of {questions.length}</p>
          <div className="quiz-button-container">
            <button className="quiz-back-button" onClick={handleBack}>Back</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelQuiz;
