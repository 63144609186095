import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import LandingPage from './LandingPage';
import ProfilePage from './ProfilePage';
import TripMakingPage from './TripMakingPage';
import TripActivitiesPage from './TripActivitiesPage';
import DestinationSuggestions from './DestinationSuggestions';
import LoadingScreen from './LoadingScreen';
import TravelQuiz from './TravelQuiz';
import { FormProvider, useFormData } from './context/FormContext';
import ErrorPage from './ErrorPage';

const App = () => {
  return (
    <FormProvider>
      <Router>
        <AppRoutes />
      </Router>
    </FormProvider>
  );
};

const AppRoutes = () => {
  const navigate = useNavigate();
  const { formData } = useFormData();
  const [destinations, setDestinations] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [globalError, setGlobalError] = useState(null);

  const resetError = () => {
    setGlobalError(null);
    setError(null);
  };

  useEffect(() => {
    if (error) {
      setGlobalError(error);
    }
  }, [error]);

  const setErrorAndNavigate = (errorMessage) => {
    setError(errorMessage);
    setGlobalError(errorMessage);
    navigate('/error');
  };

  const handleQuizSubmit = async (quizAnswers) => {
    setIsLoading(true);
    setLoadingProgress(0);
    setLoadingComplete(false);
    navigate('/loading');

    const simulateProgress = () => {
      let elapsed = 0;
      const totalDuration = 30000;
      const progressInterval = setInterval(() => {
        elapsed += 100;
        if (elapsed >= totalDuration) {
          clearInterval(progressInterval);
          return;
        }
        const newProgress = (elapsed / totalDuration) * 90;
        setLoadingProgress((prevProgress) => Math.max(prevProgress, newProgress));
      }, 100);

      return progressInterval;
    };

    let progressInterval;
    try {
      progressInterval = simulateProgress();
      
      const response = await fetch('/api/process-quiz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          quizAnswers,
          profile: formData.profile,
          tripDetails: formData.tripDetails,
          activities: formData.activities.selected,
          customActivities: formData.activities.custom,
        }),
      });

      if (!response.ok) {
        throw new Error(`Server responded with status: ${response.status}`);
      }

      const data = await response.json();
      setDestinations({
        ...data,
        quizAnswers
      });
      navigate('/destination-suggestions');
    } catch (error) {
      console.error('Error processing quiz results:', error);
      setErrorAndNavigate('Failed to process quiz results. Please try again later.');
    } finally {
      if (progressInterval) {
        clearInterval(progressInterval);
      }
      setIsLoading(false);
    }
  };

  const handleRegenerateDestination = async () => {
    setIsLoading(true);
    setError(null);
    setLoadingProgress(0);
    setLoadingComplete(false);
    navigate('/loading');
    
    const simulateProgress = () => {
      let elapsed = 0;
      const totalDuration = 15000; // 15 seconds
      return setInterval(() => {
        elapsed += 100;
        if (elapsed >= totalDuration) {
          return;
        }
        const newProgress = (elapsed / totalDuration) * 90;
        setLoadingProgress((prevProgress) => Math.max(prevProgress, newProgress));
      }, 100);
    };

    let progressInterval;
    try {
      progressInterval = simulateProgress();
      
      console.log('Starting destination regeneration...');
      const response = await fetch('/api/process-quiz', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          quizAnswers: destinations?.quizAnswers,
          profile: formData.profile,
          tripDetails: formData.tripDetails,
          activities: formData.activities.selected,
          customActivities: formData.activities.custom,
          previousDestination: destinations?.destination?.name
        }),
      });

      const data = await response.json();
      console.log('Received regenerated destination:', data);

      if (!response.ok) {
        throw new Error(data.error || `Server responded with status: ${response.status}`);
      }
      
      if (!data.destination) {
        console.error('Invalid response data:', data);
        throw new Error('Invalid destination data in response');
      }

      // Set loading to complete and update progress to 100%
      setLoadingProgress(100);
      setLoadingComplete(true);
      
      // Short delay before navigation to ensure loading state updates
      setTimeout(() => {
        setDestinations(data);
        navigate('/destination-suggestions');
        setIsLoading(false);
      }, 1000);

    } catch (error) {
      console.error('Error during regeneration:', error);
      setErrorAndNavigate(error.message || 'Failed to regenerate destination');
      setIsLoading(false);
    } finally {
      if (progressInterval) {
        clearInterval(progressInterval);
      }
    }
  };

  return (
    <Routes>
      <Route 
        path="/" 
        element={<LandingPage onPlanTrip={() => navigate('/profile')} />} 
      />
      <Route
        path="/profile"
        element={
          <ProfilePage
            onNext={(profileData) => {
              navigate('/trip-making');
            }}
          />
        }
      />
      <Route
        path="/trip-making"
        element={
          <TripMakingPage
            onNext={(details) => {
              navigate('/trip-activities');
            }}
            onBack={() => navigate('/profile')}
          />
        }
      />
      <Route
        path="/trip-activities"
        element={
          <TripActivitiesPage
            onNext={(activities, custom) => {
              navigate('/quiz');
            }}
            onBack={() => navigate('/trip-making')}
          />
        }
      />
      <Route
        path="/loading"
        element={<LoadingScreen progress={Math.floor(loadingProgress)} complete={loadingComplete} />}
      />
      <Route
        path="/destination-suggestions"
        element={
          <DestinationSuggestions
            suggestions={destinations}
            error={error}
            isLoading={isLoading}
            onBack={() => navigate('/quiz')}
            onRegenerateDestination={handleRegenerateDestination}
          />
        }
      />
      <Route
        path="/quiz"
        element={
          <TravelQuiz
            onSubmit={handleQuizSubmit}
            onBack={() => navigate('/trip-activities')}
          />
        }
      />
      <Route 
        path="/error" 
        element={
          <ErrorPage 
            error={globalError || error} 
            resetError={resetError}
            onNavigateHome={() => {
              resetError();
              navigate('/');
            }}
          />
        } 
      />
    </Routes>
  );
};

export default App;
