import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import { BiRefresh, BiHome } from 'react-icons/bi';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Outfit:wght@700&display=swap');
`;

const DestinationSuggestions = ({
  suggestions,
  error,
  isLoading,
  onBack,
  onRegenerateDestination
}) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [showShareMessage, setShowShareMessage] = useState(false);
  const [showItinerary, setShowItinerary] = useState(false);
  const navigate = useNavigate();
  const [windowDimension, setWindowDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    if (!isLoading && suggestions?.destination) {
      setShowConfetti(true);
      // Stop confetti after 5 seconds
      const timer = setTimeout(() => setShowConfetti(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [isLoading, suggestions]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimension({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleShare = async () => {
    if (navigator.share) {
      // For mobile devices that support native sharing
      try {
        await navigator.share({
          title: `Check out my next trip to ${destination.name}!`,
          text: `I'm planning to visit ${destination.name}, ${destination.country}! ${destination.explanation}`,
          url: window.location.href
        });
      } catch (err) {
        console.log('Error sharing:', err);
      }
    } else {
      // Fallback for desktop: copy to clipboard
      const shareText = `Check out my next trip to ${destination.name}, ${destination.country}!\n\n${destination.explanation}\n\nPlan your trip at: ${window.location.origin}`;
      
      try {
        await navigator.clipboard.writeText(shareText);
        setShowShareMessage(true);
        setTimeout(() => setShowShareMessage(false), 3000);
      } catch (err) {
        console.error('Failed to copy:', err);
      }
    }
  };

  if (isLoading) {
    return <LoadingMessage>Processing your perfect destination...</LoadingMessage>;
  }

  if (error) {
    return (
      <PageBackground>
        <MainContainer>
          <ErrorContainer>
            <ErrorTitle>Oops! Something went wrong</ErrorTitle>
            <p>{error}</p>
            <ErrorActions>
              <BackButton onClick={onBack}>Try Again</BackButton>
              <BackButton onClick={() => navigate('/')}>
                Return Home
              </BackButton>
            </ErrorActions>
          </ErrorContainer>
        </MainContainer>
      </PageBackground>
    );
  }

  if (!suggestions || !suggestions.destination) {
    return (
      <PageBackground>
        <MainContainer>
          <ErrorContainer>
            <ErrorTitle>No Destination Found</ErrorTitle>
            <p>We couldn't find a matching destination. Please try adjusting your preferences.</p>
            <ErrorActions>
              <BackButton onClick={onBack}>Try Again</BackButton>
              <BackButton onClick={() => navigate('/')}>
                Return Home
              </BackButton>
            </ErrorActions>
          </ErrorContainer>
        </MainContainer>
      </PageBackground>
    );
  }

  const { destination } = suggestions;

  return (
    <>
      <GlobalStyle />
      {showConfetti && (
        <Confetti
          width={windowDimension.width}
          height={windowDimension.height}
          recycle={false}
          numberOfPieces={200}
          gravity={0.3}
          colors={['#FFB6C1', '#FFC0CB', '#4A7299', '#789DBC', '#A7C1D9']}
        />
      )}
      <PageBackground>
        <MainContainer>
          <HeaderContainer>
            <Heading>Your Ideal Destination</Heading>
            <ButtonGroup>
              <IconButton onClick={() => navigate('/')} aria-label="Go to home">
                <BiHome />
              </IconButton>
              <IconButton onClick={onRegenerateDestination} rotate={true} aria-label="Regenerate destination">
                <BiRefresh />
              </IconButton>
            </ButtonGroup>
          </HeaderContainer>
          <DestinationCard>
            <h3>{destination.name}, {destination.country}</h3>
            <p><strong>Estimated Cost:</strong> {destination.estimatedCost}</p>
            <p><strong>Trip Duration:</strong> {destination.suggestedStay}</p>
            <ExplanationText>{destination.explanation}</ExplanationText>
            <HighlightsContainer>
              <h4>Highlights:</h4>
              <ul>
                {destination.highlights.map((highlight, index) => (
                  <li key={index}>{highlight}</li>
                ))}
              </ul>
            </HighlightsContainer>
            
            <ItineraryButton 
              onClick={() => setShowItinerary(!showItinerary)} 
              show={showItinerary}
            >
              {showItinerary ? 'Hide Itinerary' : 'See Itinerary'}
            </ItineraryButton>
            
            <ItineraryContainer show={showItinerary}>
              {Object.entries(destination.itinerary).map(([day, activities]) => (
                <DayContainer key={day}>
                  <DayTitle>{day.replace(/([A-Z])/g, ' $1').trim()}</DayTitle>
                  <ActivityList>
                    {Object.entries(activities).map(([timeOfDay, activity]) => (
                      <Activity key={timeOfDay}>
                        <strong>{timeOfDay}:</strong>{' '}
                        <span dangerouslySetInnerHTML={{ __html: activity }} />
                      </Activity>
                    ))}
                  </ActivityList>
                </DayContainer>
              ))}
            </ItineraryContainer>
          </DestinationCard>
          <ButtonContainer>
            <ShareButton onClick={handleShare}>
              Share This Destination
            </ShareButton>
          </ButtonContainer>
          {showShareMessage && (
            <ShareMessage>
              Link copied to clipboard! Ready to share!
            </ShareMessage>
          )}
        </MainContainer>
      </PageBackground>
    </>
  );
};

const PageBackground = styled.div`
  background: linear-gradient(135deg, #FFF0F5 0%, #FFB6C1 25%, #FFC0CB 50%, #FFB6C1 75%, #FFF0F5 100%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
`;

const MainContainer = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h2`
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  color: #333;
  font-size: 28px;
  text-align: center;
  margin-bottom: 30px;
`;

const DestinationCard = styled.div`
  background-color: #F0F8FF;
  border: 2px solid #789DBC;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;

  h3 {
    color: #4A7299;
    font-size: 24px;
    margin-bottom: 15px;
    font-family: 'Outfit', sans-serif;
  }

  p {
    font-size: 16px;
    margin: 10px 0;
    color: #333;
  }
`;

const ExplanationText = styled.p`
  font-style: italic;
  margin-top: 15px;
  line-height: 1.6;
`;

const HighlightsContainer = styled.div`
  margin-top: 20px;

  h4 {
    color: #4A7299;
    font-size: 18px;
    margin-bottom: 10px;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  li {
    margin-bottom: 5px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
`;

const BackButton = styled(Button)`
  background-color: #A7C1D9;
  color: white;

  &:hover {
    background-color: #789DBC;
  }
`;

const LoadingMessage = styled.div`
  font-size: 18px;
  color: #4A7299;
  text-align: center;
  padding: 20px;
`;

const ShareButton = styled(Button)`
  background-color: #789DBC;
  color: white;
  width: 100%;
  padding: 15px 30px;
  font-size: 18px;
  margin: 20px 0;
  
  &:hover {
    background-color: #4A7299;
    transform: scale(1.02);
  }
`;

const ShareMessage = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4A7299;
  color: white;
  padding: 15px 30px;
  border-radius: 25px;
  animation: fadeIn 0.3s ease-in;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, 20px);
    }
    to {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
`;

const ItineraryButton = styled(Button)`
  background-color: #789DBC;
  color: white;
  width: 100%;
  padding: 12px 24px;
  margin: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  
  &:hover {
    background-color: #4A7299;
  }

  &::after {
    content: '▼';
    font-size: 12px;
    transition: transform 0.3s ease;
    transform: ${props => props.show ? 'rotate(180deg)' : 'rotate(0)'};
    display: inline-block;
  }
`;

const ItineraryContainer = styled.div`
  margin-top: 20px;
  border-top: 2px solid #789DBC;
  padding-top: ${props => props.show ? '20px' : '0'};
  max-height: ${props => props.show ? '2000px' : '0'};
  opacity: ${props => props.show ? '1' : '0'};
  overflow: hidden;
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  transition: all 0.3s ease-in-out;
  transform-origin: top;
  transform: ${props => props.show ? 'scaleY(1)' : 'scaleY(0)'};
`;

const DayContainer = styled.div`
  margin-bottom: 20px;
`;

const DayTitle = styled.h4`
  color: #4A7299;
  font-size: 18px;
  margin-bottom: 10px;
  text-transform: capitalize;
`;

const ActivityList = styled.div`
  padding-left: 20px;
`;

const Activity = styled.p`
  margin: 8px 0;
  
  strong {
    color: #4A7299;
  }

  a {
    color: #4A7299;
    text-decoration: underline;
    
    &:hover {
      color: #789DBC;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: #789DBC;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.3s ease;
  
  &:hover {
    color: #4A7299;
    transform: ${props => props.rotate ? 'rotate(180deg)' : 'scale(1.1)'};
  }

  svg {
    font-size: 24px;
  }
`;

const ErrorContainer = styled.div`
  background-color: #FFF0F5;
  border: 2px solid #FFB6C1;
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
`;

const ErrorTitle = styled.h3`
  color: #FF4500;
  font-size: 20px;
  margin-bottom: 15px;
  font-family: 'Outfit', sans-serif;
`;

const ErrorActions = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
`;

export default DestinationSuggestions;
