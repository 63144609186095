import React, { useState, useEffect, useRef } from 'react';
import { Loader } from "@googlemaps/js-api-loader";
import './styles/ProfilePage.css';
import { useFormData } from './context/FormContext';

const ProfilePage = ({ onNext }) => {
  const { formData, updateFormData } = useFormData();
  const [name, setName] = useState(formData.profile.name);
  const [age, setAge] = useState(formData.profile.age);
  const [location, setLocation] = useState(formData.profile.location);
  const [step, setStep] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
  const [loadError, setLoadError] = useState(null);
  const autocompleteRef = useRef(null);

  const handleGoogleMapsError = (error) => {
    console.error('Google Maps Error:', error);
    console.error('Error Stack:', error.stack);
    
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    console.log('API Key Diagnostics:', {
      exists: !!apiKey,
      length: apiKey?.length,
      isDefined: typeof apiKey !== 'undefined',
      isEmpty: apiKey === '',
      hasWhitespace: /\s/.test(apiKey || ''),
      firstChar: apiKey ? apiKey[0] : null,
      lastChar: apiKey ? apiKey[apiKey.length - 1] : null,
      containsQuotes: apiKey ? (apiKey.includes('"') || apiKey.includes("'")) : false
    });

    console.log('Google Object Status:', {
      windowExists: typeof window !== 'undefined',
      googleExists: typeof window.google !== 'undefined',
      mapsExists: window.google?.maps ? true : false,
      placesExists: window.google?.maps?.places ? true : false
    });

    setLoadError(error.message || 'Failed to load Google Maps');
    setIsGoogleLoaded(false);
  };

  useEffect(() => {
    const initGoogleMaps = async () => {
      console.log('Starting Google Maps initialization...');
      try {
        console.log('API Key Length:', process.env.REACT_APP_GOOGLE_MAPS_API_KEY?.length);
        
        const loader = new Loader({
          apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          version: "weekly",
          libraries: ["places"]
        });

        console.log('Loader created, attempting to load...');
        
        await loader.load();
        console.log('Loader completed');
        
        if (window.google && window.google.maps) {
          console.log('Google Maps loaded successfully');
          setIsGoogleLoaded(true);
          setLoadError(null);
        } else {
          throw new Error('Google Maps failed to initialize properly - window.google.maps not available');
        }
      } catch (error) {
        console.error('Detailed error in Google Maps initialization:', {
          error: error,
          message: error.message,
          stack: error.stack,
          type: error.constructor.name
        });
        handleGoogleMapsError(error);
      }
    };

    initGoogleMaps();
  }, []);

  useEffect(() => {
    window.gm_authFailure = () => {
      const error = new Error('Google Maps authentication failed - Please check API key configuration');
      handleGoogleMapsError(error);
    };

    return () => {
      delete window.gm_authFailure;
    };
  }, []);

  useEffect(() => {
    if (!isGoogleLoaded || step !== 1 || !autocompleteRef.current || !window.google?.maps?.places) {
      return;
    }

    try {
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteRef.current,
        {
          types: ['(cities)'],
          fields: ['formatted_address']
        }
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.formatted_address) {
          setLocation(place.formatted_address);
        }
      });

      return () => {
        if (window.google?.maps?.event) {
          window.google.maps.event.clearInstanceListeners(autocomplete);
        }
      };
    } catch (error) {
      handleGoogleMapsError(error);
    }
  }, [isGoogleLoaded, step]);

  useEffect(() => {
    if (isTransitioning) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isTransitioning]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case 'name':
        setName(value);
        break;
      case 'age':
        setAge(value);
        break;
      case 'location':
        setLocation(value);
        break;
      default:
        console.warn(`Unhandled input field: ${id}`);
        break;
    }
  };

  const nextStep = () => {
    if (step < 1 && !isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setStep(step + 1);
        setIsTransitioning(false);
      }, 300);
    } else if (!isTransitioning) {
      const profileData = { name, age, location };
      console.log('Profile Data Submitted:', profileData);
      updateFormData('profile', profileData);
      onNext(profileData);
    }
  };

  const prevStep = () => {
    if (step > 0 && !isTransitioning) {
      setIsTransitioning(true);
      setTimeout(() => {
        setStep(step - 1);
        setIsTransitioning(false);
      }, 300);
    }
  };

  return (
    <div className="page-background">
      <div className="main-container">
        <h2 className="heading">
          {step === 0 ? "Create Your Profile" : "Select your starting location"}
        </h2>
        <div className="step-container">
          <div className={`step-content ${step === 0 ? 'active' : 'inactive-prev'}`}>
            <label htmlFor="name" className="label">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleInputChange}
              className="input"
              placeholder="Enter your name"
            />
            <label htmlFor="age" className="label">Age:</label>
            <input
              type="number"
              id="age"
              value={age}
              onChange={handleInputChange}
              className="input"
              placeholder="Enter your age"
            />
          </div>
          <div className={`step-content ${step === 1 ? 'active' : 'inactive-next'}`}>
            <label htmlFor="location" className="label">Location:</label>
            <input
              ref={autocompleteRef}
              type="text"
              id="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="input location-input"
              placeholder={isGoogleLoaded ? "Start typing your city..." : "Loading Google Maps..."}
              disabled={!isGoogleLoaded}
            />
            {loadError && (
              <div className="error-message">
                Error loading Google Maps: {loadError}
              </div>
            )}
          </div>
        </div>
        <div className="button-container">
          {step > 0 && (
            <button className="back-button" onClick={prevStep} disabled={isTransitioning}>
              Back
            </button>
          )}
          <button
            className="next-button"
            onClick={nextStep}
            disabled={(step === 0 && (!name || !age)) || (step === 1 && !location) || isTransitioning}
          >
            {step === 1 ? 'Submit' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
