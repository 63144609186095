import React, { createContext, useContext, useState } from 'react';

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    profile: {
      name: '',
      age: '',
      location: ''
    },
    tripDetails: {
      startDate: '',
      endDate: '',
      travelDistance: '',
      budget: '',
      travelGroup: '',
      duration: 0
    },
    activities: {
      selected: [],
      custom: ''
    },
    quizAnswers: []
  });

  const updateFormData = (section, data) => {
    setFormData(prev => {
      const newData = {
        ...prev,
        [section]: {
          ...prev[section],
          ...data
        }
      };
      console.log('Form Data Updated:', {
        section,
        newSectionData: newData[section],
        fullFormData: newData
      });
      return newData;
    });
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormData = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormData must be used within a FormProvider');
  }
  return context;
}; 