import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import './styles/LoadingScreen.css';

const travelFacts = [
  "Antarctica, Earth's largest desert, is bigger than the Sahara.",
  "Sudan boasts more ancient pyramids than Egypt.",
  "The world's shortest commercial flight in Scotland lasts only 57 seconds.",
  "Japan is an archipelago of over 6,800 islands.",
  "The Great Wall of China is actually invisible from space with the naked eye.",
  "Venice is built on 118 islands connected by over 400 bridges.",
  "The Netherlands exports more flowers than any other country in the world.",
  "Singapore's Changi Airport features a 40-meter indoor waterfall.",
  "Bolivia's Palacio de Sal is a hotel built entirely of salt blocks.",
  "The world's oldest known living tree has been alive for over 5,000 years.",
  "The Eiffel Tower grows up to 15 cm taller on hot summer days.",
  "Australia is the only continent without any active volcanoes.",
  "The Pacific Ocean covers more area than all of Earth's landmass combined.",
  "A Norwegian town experiences 69 days of uninterrupted sunlight each year.",
  "The Philippines features a navigable underground river 8.2 km long.",
  "Oymyakon, Russia, is the coldest permanently inhabited place on Earth.",
  "Vietnam's Son Doong Cave is so massive it has its own weather system.",
  "The Sahara Desert occasionally experiences snowfall.",
  "Australia's Lake Hillier remains stubbornly pink year-round.",
  "Turkmenistan's 'Door to Hell' has been burning continuously since 1971.",
  "Indonesia's Kawah Ijen volcano erupts electric-blue lava.",
  "Venezuela's Catatumbo lightning can strike up to 280 times per hour.",
  "The world's largest swimming pool in Chile stretches for a kilometer.",
  "Dinner in the Sky in Belgium serves meals 150 feet above ground.",
  "The shortest international bridge spans just 3.2 meters between two countries.",
  "Cars in Yakutsk, Russia, run 24/7 in winter to avoid engine freezing.",
  "The Principality of Sealand claims to be a sovereign state on a sea fort.",
  "Sweden's Ice Hotel is rebuilt every year using snow and ice from a nearby river.",
  "Poland's 'Crooked Forest' features 400 pine trees all bent at 90-degree angles.",
  "Canada's Magnetic Hill creates an illusion of cars rolling uphill.",
  "A Tokyo café allows customers to pet otters while enjoying their coffee.",
  "The world's largest hedge maze in France covers nearly 40 acres.",
  "Maldives offers underwater hotel rooms with 180-degree views of marine life.",
  "Ukraine's 'Tunnel of Love' is a lush green railway tunnel.",
  "A restaurant in China delivers food via miniature roller coasters.",
  "Vietnam's Hang Son Doong cave could fit a 40-story skyscraper inside.",
  "Canada's Spotted Lake changes colors throughout the year due to mineral deposits.",
  "Bhutan measures its country's success by Gross National Happiness.",
  "Trinidad's Pitch Lake is the world's largest natural deposit of asphalt.",
  "New Zealand's Hobbiton from 'The Lord of the Rings' is a real, visitable village.",
  "Norway's Svalbard Global Seed Vault stores copies of the world's seeds.",
  "Denmark relocated the entire Rubjerg Knude lighthouse to save it from coastal erosion.",
  "Scotland's Fingal's Cave features naturally formed hexagonal basalt columns.",
  "Mexico's Cave of Crystals contains beams of selenite up to 39 feet long.",
  "Bolivia's Salar de Uyuni becomes the world's largest mirror when it rains.",
  "Ethiopia's Danakil Depression is officially the hottest place on Earth.",
  "In Iceland's Silfra fissure, you can dive between two tectonic plates.",
  "Singapore's airport houses the world's tallest indoor waterfall at 40 meters high.",
  "China's Zhangjiajie National Forest Park inspired the floating mountains in 'Avatar'.",
  "The Dallol volcano in Ethiopia features neon-colored hot springs.",
  "Japan's Jigokudani Monkey Park is famous for its hot spring-bathing snow monkeys.",
  "The Chocolate Hills of Bohol, Philippines, resemble giant Hershey's Kisses.",
  "Costa Rica's Río Celeste turns bright blue due to a chemical reaction.",
  "Montana's Glacier National Park has a glacier that 'sings' or 'screams'.",
  "The Richat Structure in Mauritania looks like a giant blue eye from space.",
  "Azerbaijan's Yanar Dag has been continuously on fire for over 70 years.",
  "Thailand's Maeklong Railway Market sets up and dismantles daily for passing trains.",
  "The Tianzi Mountains in China look like floating pillars due to sea of clouds.",
  "Namibia's Skeleton Coast is littered with shipwrecks visible from the shore.",
  "The Crooked Forest in Poland has 400 pine trees all bent at the same angle.",
  "Mexico's Hidden Beach is a crater with a secret beach, accessible only by swimming.",
  "Norway's Preikestolen cliff offers a heart-stopping view 604 meters above a fjord.",
  "Portugal's Capela do Senhor da Pedra is a chapel built on a rock in the Atlantic Ocean.",
  "The Grand Prismatic Spring in Yellowstone displays a rainbow of vibrant colors.",
  "New Zealand's Waitomo Glowworm Caves light up like a starry night sky.",
  "Russia's Lake Baikal contains 22% of the world's fresh surface water.",
  "The Door to Hell in Turkmenistan is a gas crater that's been burning since 1971.",
  "Australia's Lake Hillier is a vibrant pink color due to its high salt content.",
  "The Salar de Uyuni in Bolivia becomes the world's largest mirror when flooded.",
  "Japan's Hashima Island looks like a battleship and inspired a James Bond villain lair.",
  "The Marble Caves of Chile Chico change color with the seasons and water levels.",
  "Denmark's Rubjerg Knude Lighthouse was moved 70 meters inland to save it from the sea.",
  "The Mendenhall Ice Caves in Alaska allow you to walk inside a glacier.",
  "Antelope Canyon in Arizona creates surreal light beams in its narrow passages.",
  "The Nazca Lines in Peru are ancient geoglyphs only fully visible from the air.",
];

const loadingSteps = [
  "Compiling your travel preferences...",
  "Searching for the best destinations...",
  "Crafting your perfect itinerary...",
  "Finalizing your dream vacation..."
];

const LoadingScreen = ({ initialProgress, complete, onComplete, quizData }) => {
  const [currentFact, setCurrentFact] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [sessionId, setSessionId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!quizData) return;

    fetch('/api/start-itinerary', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(quizData)
    })
    .then(response => response.json())
    .then(data => {
      setSessionId(data.sessionId);
      setProgress(data.progress || 0);
    })
    .catch(err => setError(err.message));
  }, [quizData]);

  useEffect(() => {
    let pollInterval;
    
    if (sessionId) {
      pollInterval = setInterval(async () => {
        try {
          const response = await fetch(`/api/itinerary-status/${sessionId}`);
          const data = await response.json();
          
          if (data.progress !== undefined) {
            setProgress(data.progress);
          }
          
          if (data.status === 'complete' && data.result) {
            clearInterval(pollInterval);
            if (onComplete) {
              onComplete(data.result);
            }
          } else if (data.status === 'error') {
            clearInterval(pollInterval);
            setError(data.error || 'An error occurred');
          }
        } catch (err) {
          console.error('Polling error:', err);
          setError(err.message);
        }
      }, 1000);
    }

    return () => {
      if (pollInterval) clearInterval(pollInterval);
    };
  }, [sessionId, onComplete]);

  // Update the step based on progress
  useEffect(() => {
    setCurrentStep(Math.min(Math.floor(progress / 25), 3));
  }, [progress]);

  useEffect(() => {
    const factInterval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * travelFacts.length);
      setCurrentFact(travelFacts[randomIndex]);
    }, 6000); // Change fact every 6 seconds

    return () => clearInterval(factInterval);
  }, []);

  return (
    <LoadingContainer className="page-background-loading">
      <FactContainer>
        <FadingText key={currentFact}>{currentFact}</FadingText>
      </FactContainer>
      <ProgressBar progress={progress} />
      {error ? (
        <LoadingText>Error: {error}</LoadingText>
      ) : (
        <LoadingText>
          {complete ? 'Complete!' : loadingSteps[currentStep]}
        </LoadingText>
      )}
      <StepContainer>
        {loadingSteps.map((step, index) => (
          <StepDot key={index} active={index <= currentStep} />
        ))}
      </StepContainer>
    </LoadingContainer>
  );
};

const fadeInOut = keyframes`
  0% { opacity: 0; transform: translateY(10px); }
  5% { opacity: 1; transform: translateY(0); }
  95% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-10px); }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const FactContainer = styled.div`
  height: 60px;
  margin-bottom: 20px;
  text-align: center;
  width: 80%;
  max-width: 600px;
  font-weight: bold;
`;

const FadingText = styled.p`
  font-size: 18px;
  color: #789DBC;
  animation: ${fadeInOut} 6s ease-in-out;
`;

const ProgressBarContainer = styled.div`
  width: 300px;
  height: 20px;
  background-color: #E0E0E0;
  border-radius: 10px;
  overflow: hidden;
`;

const ProgressBarFill = styled.div.attrs(props => ({
  style: {
    width: `${props.progress}%`,
  },
}))`
  height: 100%;
  background-color: #789DBC;
  border-radius: 10px;
  transition: width 0.5s ease-out;
`;

const ProgressBar = ({ progress }) => (
  <ProgressBarContainer>
    <ProgressBarFill progress={progress} />
  </ProgressBarContainer>
);

const LoadingText = styled.p`
  margin-top: 30px;
  font-size: 28px;
  color: #789DBC;
  text-align: center;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StepDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#789DBC' : '#E0E0E0'};
  margin: 0 5px;
  transition: background-color 0.3s ease-out;
`;

export default LoadingScreen;
