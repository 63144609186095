import React, { useEffect, useRef, useState } from 'react';
import './App.css';

const LandingPage = ({ onPlanTrip }) => {
  const pageRef = useRef(null);
  const [cloudsFlying, setCloudsFlying] = useState(false);
  const hoverTimeoutRef = useRef(null);
  const cloudRefs = [
    useRef(null), useRef(null), useRef(null), useRef(null),
    useRef(null), useRef(null), useRef(null), useRef(null),
    useRef(null), useRef(null), useRef(null), useRef(null)
  ];

  const handleMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }

    hoverTimeoutRef.current = setTimeout(() => {
      cloudRefs.forEach((ref) => {
        if (ref.current) {
          ref.current.style.animationPlayState = 'paused';
          
          const computedStyle = window.getComputedStyle(ref.current);
          const transform = computedStyle.transform;
          
          ref.current.style.transform = transform;
          
          ref.current.style.animation = 'none';
          
          const rect = ref.current.getBoundingClientRect();
          ref.current.style.setProperty('--current-x', `${rect.left}px`);
          ref.current.style.setProperty('--current-y', `${rect.top}px`);
        }
      });
      
      setCloudsFlying(true);
    }, 150);
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }

    if (cloudsFlying) {
      cloudRefs.forEach((ref) => {
        if (ref.current) {
          ref.current.style.removeProperty('animation');
          ref.current.style.removeProperty('transform');
          ref.current.style.removeProperty('--current-x');
          ref.current.style.removeProperty('--current-y');
          ref.current.style.removeProperty('animation-play-state');
        }
      });
      
      setCloudsFlying(false);
    }
  };

  useEffect(() => {
    console.log("Landing page mounted");
    document.body.classList.add('no-scroll');

    const handleMouseMove = (e) => {
      if (pageRef.current) {
        const x = e.clientX / window.innerWidth;
        const y = e.clientY / window.innerHeight;
        pageRef.current.style.setProperty('--mouse-x', `${x * 100}%`);
        pageRef.current.style.setProperty('--mouse-y', `${y * 100}%`);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.body.classList.remove('no-scroll');
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <div 
        className="animated-gradient-background" 
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          background: `linear-gradient(
            135deg,
            #FFC0CB 0%,
            #FEF9F2 20%,
            #FFC0CB 40%,
            #FEF9F2 60%,
            #FFC0CB 80%,
            #FEF9F2 100%
          )`,
          backgroundSize: '1000% 1000%',
          animation: 'gradientShift 120s ease infinite, colorPulse 40s ease-in-out infinite',
          pointerEvents: 'none'
        }}
      />
      <div 
        className="mouse-gradient-overlay"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          background: `radial-gradient(
            circle 800px at var(--mouse-x, 50%) var(--mouse-y, 50%),
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 192, 203, 0.4) 20%,
            rgba(255, 255, 255, 0.2) 30%,
            transparent 65%
          )`,
          pointerEvents: 'none',
          mixBlendMode: 'overlay',
          transition: 'background 0.1s ease',
          filter: 'blur(30px)',
        }}
      />
      <div 
        className="page-background page-background-landing" 
        ref={pageRef}
        style={{
          position: 'relative',
          zIndex: 2,
          background: 'transparent'
        }}
      >
        <div className="plane-container">
          <div className="plane-trail"></div>
          <div className="plane">🛩️</div>
        </div>
        <div className={`clouds ${cloudsFlying ? 'flying' : ''}`}>
          <div ref={cloudRefs[0]} className="cloud cloud1">☁️</div>
          <div ref={cloudRefs[1]} className="cloud cloud2">☁️</div>
          <div ref={cloudRefs[2]} className="cloud cloud3">☁️</div>
          <div ref={cloudRefs[3]} className="cloud cloud4">☁️</div>
          <div ref={cloudRefs[4]} className="cloud cloud5">☁️</div>
          <div ref={cloudRefs[5]} className="cloud cloud6">☁️</div>
          <div ref={cloudRefs[6]} className="cloud cloud7">☁️</div>
          <div ref={cloudRefs[7]} className="cloud cloud8">☁️</div>
          <div ref={cloudRefs[8]} className="cloud cloud9">☁️</div>
          <div ref={cloudRefs[9]} className="cloud cloud10">☁️</div>
          <div ref={cloudRefs[10]} className="cloud cloud11">☁️</div>
          <div ref={cloudRefs[11]} className="cloud cloud12">☁️</div>
        </div>

        <div className="center-content">
          <h1 className="welcome-text">Welcome</h1>
          <button 
            className="button plan-trip-button" 
            onClick={onPlanTrip}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Plan Your Trip
          </button>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
