import React, { useState, useCallback, useEffect } from 'react';
import './styles/TripActivitiesPage.css';
import { useFormData } from './context/FormContext';

const activities = [
  "Surfing", "Climbing", "Bars", "Spa", "Hiking", "Museums", 
  "Shopping", "Restaurants", "Sightseeing", "Beach", "Nightlife", 
  "Water Sports", "Skiing", "Yoga", "Cycling"
];

const TripActivitiesPage = ({ onNext, onBack }) => {
  const { updateFormData } = useFormData();
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [customActivities, setCustomActivities] = useState('');
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    document.body.classList.add('no-scroll');
    setAnimate(true);
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  const handleActivityToggle = useCallback((activity) => {
    setSelectedActivities(prev => 
      prev.includes(activity) 
        ? prev.filter(a => a !== activity)
        : [...prev, activity]
    );
  }, []);

  const handleCustomActivityChange = useCallback((e) => {
    setCustomActivities(e.target.value);
  }, []);

  const handleNext = () => {
    const activitiesData = {
      selected: selectedActivities,
      custom: customActivities
    };
    console.log('Activities Submitted:', activitiesData);
    updateFormData('activities', activitiesData);
    onNext(selectedActivities, customActivities);
  };

  return (
    <div className="page-background">
      <div className="main-container">
        <h2 className="heading">Choose Your Activities</h2>
        <div className="activity-button-container">
          {activities.map((activity, index) => (
            <button 
              key={activity}
              onClick={() => handleActivityToggle(activity)}
              className={`activity-button ${selectedActivities.includes(activity) ? 'selected' : ''} ${animate ? 'fill-up' : ''}`}
              style={{animationDelay: `${index * 0.05}s`}}
            >
              {activity}
            </button>
          ))}
        </div>
        <div className="input-wrapper">
          <label htmlFor="customActivities" className="label">Other activities you're interested in:</label>
          <textarea
            id="customActivities"
            value={customActivities}
            onChange={handleCustomActivityChange}
            placeholder="E.g., local cuisine tasting, photography, etc."
            className="textarea"
          />
        </div>
        <div className="button-container">
          <button className="back-button" onClick={onBack}>Back</button>
          <button 
            className="next-button" 
            onClick={handleNext} 
            disabled={selectedActivities.length === 0 && !customActivities.trim()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default TripActivitiesPage;
